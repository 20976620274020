//------------
// These functions are meant to accept the state right before being shipped off to the API.
// The objective is to contain a source of truth in regards to what keys are required and what type of key are we validating.
// Running our state through a shaper should cut down on hard to read ternaries / conditionals inside of the container and/or the validation service itself.
// Each form will ideally have it's own shaper.
//
// example object for validation:
//
// firstName: {                           // the property- will be returned as a key after validation.
//   type: 'input',                       // the type of validation to be run on this particular property.
//   required: true,                      // whether or not this property is required.
//   value: state.firstName,              // the value off of the incoming arguments.
//   message: 'a first name das required',// an optional response message. If not provided, it will attach responses.firstName
// }
//
//------------
import { AppConstants } from '../../constants';
import { TYPE_ORGANIZATION, TYPE_PATIENT } from '../../constants/Types';

const breezeKey = AppConstants.RHINOPAY_TYPE.find((pay) => pay.Key === AppConstants.PAYMENT_TYPE_BREEZE).Key;

export function shapeSecureUserProfile(state) {
  return {
    loginEmail: {
      type: 'email',
      required: true,
      value: state.loginEmail,
    },
  };
}

export function shapeMemberProfile(state) {
  return {
    firstName: {
      type: 'input',
      required: true,
      value: state.firstName,
    },
    lastName: {
      type: 'input',
      required: true,
      value: state.lastName,
    },
    roles: {
      type: 'memberRoles',
      required: true,
      value: state.roles,
      message: 'Members require at least one role.',
    },
    loginEmail: {
      type: 'email',
      required: true,
      value: state.loginEmail,
    },
    timeZoneId: {
      type: 'select',
      required: state.afterHours,
      value: state.timeZoneId,
    },
  };
}

export function shapeContactProfile(state, isIntegrated = false) {
  return {
    typeId: {
      type: 'input',
      required: true,
      value: state.typeId,
    },
    firstName: {
      type: 'input',
      required: true,
      value: state.firstName,
      isDisabled: isIntegrated,
      message: state.typeId === TYPE_ORGANIZATION ? 'Display Name is required' : null,
    },
    lastName: {
      type: 'input',
      required: state.typeId !== TYPE_ORGANIZATION, // type organization
      value: state.lastName,
      isDisabled: isIntegrated,
    },
    birthday: {
      type: 'birthday',
      required: state.typeId === TYPE_PATIENT, // type patient
      value: state.birthday,
      isDisabled: isIntegrated,
    },
    userEmails: {
      type: 'emails',
      required: false,
      value: state.emails,
      isDisabled: isIntegrated,
    },
    userPhones: {
      type: 'phones',
      required: false,
      value: state.phones,
      isDisabled: isIntegrated,
    },
    toChannelId: {
      type: 'select',
      required: state.typeId === TYPE_ORGANIZATION,
      value: state.rhinograms && Object.values(state.rhinograms)[0]?.toChannelId,
      message: 'Channel is required',
    },
    toOrganizationId: {
      type: 'select',
      required: state.typeId === TYPE_ORGANIZATION,
      value: state.rhinograms && Object.values(state.rhinograms)[0]?.toOrganizationId,
    },
  };
}

export function shapeCPProfile(state) {
  return {
    firstName: {
      type: 'input',
      required: true,
      value: state.firstName,
    },
    lastName: {
      type: 'input',
      required: true,
      value: state.lastName,
    },
    userEmails: {
      type: 'emails',
      required: false,
      value: state.userEmails,
    },
    userPhones: {
      type: 'phones',
      required: false,
      value: state.userPhones,
    },
    birthday: {
      type: 'birthday',
      required: state.typeId === TYPE_PATIENT, // type patient
      value: state.birthday,
    },
  };
}

export function shapeOffice(state) {
  return {
    name: {
      type: 'input',
      required: true,
      value: state.name,
    },
    street1: {
      type: 'input',
      required: true,
      value: state.address.dirtyStreet1,
    },
    city: {
      type: 'city',
      required: true,
      value: state.address.dirtyCity,
    },
    state: {
      type: 'state',
      required: true,
      value: state.address.dirtyState,
    },
    zip: {
      type: 'zip',
      required: true,
      value: state.address.dirtyZip,
    },
  };
}

export function shapeMemberBilling(state) {
  if (state.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD) {
    return {
      firstName: {
        type: 'contactInput',
        required: true,
        value: state.firstName,
      },
      lastName: {
        type: 'contactInput',
        required: true,
        value: state.lastName,
      },
      creditCardNumber: {
        type: 'creditcard',
        required: true,
        value: state.payment.ccNumber,
      },
      creditCardExpYear: {
        type: 'select',
        required: true,
        value: state.payment.ccExpYear,
      },
      creditCardExpMonth: {
        type: 'select',
        required: true,
        value: state.payment.ccExpMonth,
      },
      creditCardVerificationValue: {
        type: 'cvv',
        required: true,
        value: state.payment.ccCvv,
      },
      creditCardZip: {
        type: 'zip',
        required: true,
        value: state.payment.creditCardZip,
      },
      reCaptcha: {
        type: 'captcha',
        value: state.reCaptchaToken,
        required: true,
      },
    };
  } else if (state.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.BANK) {
    return {
      firstName: {
        type: 'input',
        required: true,
        value: state.firstName,
      },
      lastName: {
        type: 'input',
        required: true,
        value: state.lastName,
      },
      bankName: {
        type: 'input',
        required: true,
        value: state.payment.bankName,
      },
      bankAccountNumber: {
        type: 'input',
        required: true,
        value: state.payment.bankAccNum,
      },
      bankRoutingNumber: {
        type: 'input',
        required: true,
        value: state.payment.bankRouteNum,
      },
    };
  }
  return {};
}

export function shapeMemberBillingContact(state) {
  return {
    firstName: {
      type: 'contactInput',
      required: true,
      value: state.contactFirstName,
    },
    lastName: {
      type: 'contactInput',
      required: true,
      value: state.contactLastName,
    },
    billingContactPhone: {
      type: 'phone',
      required: false,
      value: state.contactPhone,
    },
    contactEmail: {
      type: 'email',
      required: true,
      value: state.contactEmail,
    },
    billingStreet1: {
      type: 'input',
      required: true,
      value: state.street1,
    },
    billingCity: {
      type: 'city',
      required: true,
      value: state.city,
    },
    billingState: {
      type: 'state',
      required: true,
      value: state.state,
    },
    billingZip: {
      type: 'zip',
      required: true,
      value: state.zip,
    },
  };
}

export function shapeOrganization(state, isCcr) {
  if (!state.billingChecked && state.selectedBillingOpt === 'newCust' && state.billing.creditCard) {
    return {
      name: {
        type: 'organizationNameInput',
        required: true,
        value: state.name,
      },
      street1: {
        type: 'input',
        required: true,
        value: state.street1,
      },
      city: {
        type: 'city',
        required: true,
        value: state.city,
      },
      state: {
        type: 'state',
        required: true,
        value: state.state,
      },
      zip: {
        type: 'zip',
        required: true,
        value: state.zip,
      },
      businessPhone: {
        type: 'phone',
        required: false,
        value: state.businessPhone,
      },
      businessEmail: {
        type: 'email',
        required: false,
        value: state.businessEmail,
      },
      contactName: {
        type: 'input',
        required: false,
        value: state.contactName,
      },
      contactPhone: {
        type: 'phone',
        required: false,
        value: state.contactPhone,
      },
      contactEmail: {
        type: 'email',
        required: false,
        value: state.contactEmail,
      },
      salesRep: {
        type: 'select',
        required: false,
        value: state.billing.salesRef,
      },
      billingProductId: {
        type: 'select',
        required: true,
        value: state.billing.billManagerProdId,
      },
      firstName: { // billing first name
        type: 'contactInput',
        required: true,
        value: state.billing.firstName,
      },
      lastName: {
        type: 'contactInput',
        required: true,
        value: state.billing.lastName,
      },
      billingStreet1: {
        type: 'input',
        required: true,
        value: state.billing.street1,
      },
      billingCity: {
        type: 'city',
        required: true,
        value: state.billing.city,
      },
      billingState: {
        type: 'state',
        required: true,
        value: state.billing.state,
      },
      billingZip: {
        type: 'zip',
        required: true,
        value: state.billing.zip,
      },
      creditCardNumber: {
        type: 'creditcard',
        required: true,
        value: state.billing.payment.ccNumber,
      },
      creditCardExpMonth: {
        type: 'select',
        required: true,
        value: state.billing.payment.ccExpMonth,
      },
      creditCardExpYear: {
        type: 'select',
        required: true,
        value: state.billing.payment.ccExpYear,
      },
      creditCardVerificationValue: {
        type: 'cvv',
        required: true,
        value: state.billing.payment.ccCvv,
      },
      creditCardZip: {
        type: 'zip',
        required: true,
        value: state.billing.payment.creditCardZip,
      },
      billingContactFirstName: {
        type: 'contactInput',
        required: true,
        value: state.billing.contactFirstName,
      },
      billingContactLastName: {
        type: 'contactInput',
        required: true,
        value: state.billing.contactLastName,
      },
      billingContactPhone: {
        type: 'phone',
        required: false,
        value: state.billing.contactPhone,
      },
      billingContactEmail: {
        type: 'email', // needs to be required and email val is done via api
        required: true,
        value: state.billing.contactEmail,
      },
      installAmount: {
        type: 'number',
        required: false,
        value: state.billing.installAmount,
      },
    };
  } else if (state.selectedBillingOpt === 'existCust') {
    return {
      billManagerCustId: {
        type: 'input',
        required: true,
        value: state.billing.billManagerCustId,
      },
      name: {
        type: 'organizationNameInput',
        required: true,
        value: state.name,
      },
      street1: {
        type: 'input',
        required: true,
        value: state.street1,
      },
      city: {
        type: 'city',
        required: true,
        value: state.city,
      },
      state: {
        type: 'state',
        required: true,
        value: state.state,
      },
      zip: {
        type: 'zip',
        required: true,
        value: state.zip,
      },
      businessPhone: {
        type: 'phone',
        required: false,
        value: state.businessPhone,
      },
      businessEmail: {
        type: 'email',
        required: false,
        value: state.businessEmail,
      },
      contactName: {
        type: 'input',
        required: false,
        value: state.contactName,
      },
      contactPhone: {
        type: 'phone',
        required: false,
        value: state.contactPhone,
      },
      contactEmail: {
        type: 'email',
        required: false,
        value: state.contactEmail,
      },
    };
  } else if (!state.billingChecked && state.selectedBillingOpt === 'newCust' && state.billing.hasBankAccount) {
    return {
      name: {
        type: 'organizationNameInput',
        required: true,
        value: state.name,
      },
      street1: {
        type: 'input',
        required: true,
        value: state.street1,
      },
      city: {
        type: 'city',
        required: true,
        value: state.city,
      },
      state: {
        type: 'state',
        required: true,
        value: state.state,
      },
      zip: {
        type: 'zip',
        required: true,
        value: state.zip,
      },
      businessPhone: {
        type: 'phone',
        required: false,
        value: state.businessPhone,
      },
      businessEmail: {
        type: 'email',
        required: false,
        value: state.businessEmail,
      },
      contactName: {
        type: 'input',
        required: false,
        value: state.contactName,
      },
      contactPhone: {
        type: 'phone',
        required: false,
        value: state.contactPhone,
      },
      contactEmail: {
        type: 'email',
        required: false,
        value: state.contactEmail,
      },
      salesRep: {
        type: 'select',
        required: false,
        value: state.billing.salesRef,
      },
      billingProductId: {
        type: 'select',
        required: true,
        value: state.billing.billManagerProdId,
      },
      firstName: { // billing first name
        type: 'input',
        required: true,
        value: state.billing.firstName,
      },
      lastName: {
        type: 'input',
        required: true,
        value: state.billing.lastName,
      },
      billingStreet1: {
        type: 'input',
        required: true,
        value: state.billing.street1,
      },
      billingCity: {
        type: 'city',
        required: true,
        value: state.billing.city,
      },
      billingState: {
        type: 'state',
        required: true,
        value: state.billing.state,
      },
      billingZip: {
        type: 'zip',
        required: true,
        value: state.billing.zip,
      },
      bankName: {
        type: 'input',
        required: true,
        value: state.billing.payment.bankName,
      },
      bankAccNum: {
        type: 'input',
        required: true,
        value: state.billing.payment.bankAccNum,
      },
      bankRouteNum: {
        type: 'input',
        required: true,
        value: state.billing.payment.bankRouteNum,
      },
      billingContactFirstName: {
        type: 'contactInput',
        required: true,
        value: state.billing.contactFirstName,
      },
      billingContactLastName: {
        type: 'contactInput',
        required: true,
        value: state.billing.contactLastName,
      },
      billingContactPhone: {
        type: 'phone',
        required: false,
        value: state.billing.contactPhone,
      },
      billingContactEmail: {
        type: 'input', // needs to be required and email val is done via api
        required: true,
        value: state.billing.contactEmail,
      },
      installAmount: {
        type: 'number',
        required: false,
        value: state.billing.installAmount,
      },
    };
  } else if (!state.billingChecked && state.selectedBillingOpt === 'newCust' && !state.billing.hasBankAccount && !state.billing.creditCard) {
    return {
      name: {
        type: 'organizationNameInput',
        required: true,
        value: state.name,
      },
      street1: {
        type: 'input',
        required: true,
        value: state.street1,
      },
      city: {
        type: 'city',
        required: true,
        value: state.city,
      },
      state: {
        type: 'state',
        required: true,
        value: state.state,
      },
      zip: {
        type: 'zip',
        required: true,
        value: state.zip,
      },
      businessPhone: {
        type: 'phone',
        required: false,
        value: state.businessPhone,
      },
      businessEmail: {
        type: 'email',
        required: false,
        value: state.businessEmail,
      },
      contactName: {
        type: 'input',
        required: false,
        value: state.contactName,
      },
      contactPhone: {
        type: 'phone',
        required: false,
        value: state.contactPhone,
      },
      contactEmail: {
        type: 'email',
        required: false,
        value: state.contactEmail,
      },
      salesRep: {
        type: 'select',
        required: false,
        value: state.billing.salesRef,
      },
      billingProductId: {
        type: 'select',
        required: true,
        value: state.billing.billManagerProdId,
      },
      billingStreet1: {
        type: 'input',
        required: true,
        value: state.billing.street1,
      },
      billingCity: {
        type: 'city',
        required: true,
        value: state.billing.city,
      },
      billingState: {
        type: 'state',
        required: true,
        value: state.billing.state,
      },
      billingZip: {
        type: 'zip',
        required: true,
        value: state.billing.zip,
      },
      billingContactFirstName: {
        type: 'contactInput',
        required: true,
        value: state.billing.contactFirstName,
      },
      billingContactLastName: {
        type: 'contactInput',
        required: true,
        value: state.billing.contactLastName,
      },
      billingContactPhone: {
        type: 'phone',
        required: false,
        value: state.billing.contactPhone,
      },
      billingContactEmail: {
        type: 'input', // needs to be required and email val is done via api
        required: true,
        value: state.billing.contactEmail,
      },
      installAmount: {
        type: 'number',
        required: false,
        value: state.billing.installAmount,
      },
    };
  }

  return {
    name: {
      type: 'organizationNameInput',
      required: true,
      value: state.name,
    },
    street1: {
      type: 'input',
      required: true,
      value: state.street1,
    },
    city: {
      type: 'city',
      required: true,
      value: state.city,
    },
    state: {
      type: 'state',
      required: true,
      value: state.state,
    },
    zip: {
      type: 'zip',
      required: true,
      value: state.zip,
    },
    businessPhone: {
      type: 'phone',
      required: false,
      value: state.businessPhone,
    },
    businessEmail: {
      type: 'email',
      required: false,
      value: state.businessEmail,
    },
    contactName: {
      type: 'input',
      required: false,
      value: state.contactName,
    },
    contactPhone: {
      type: 'phone',
      required: false,
      value: state.contactPhone,
    },
    contactEmail: {
      type: 'email',
      required: false,
      value: state.contactEmail,
    },
    billManagerCustId: {
      type: 'input',
      required: false,
      value: state.billManagerCustId,
    },
    paymentType: {
      type: 'select',
      required: state.isRhinopayEnabled && isCcr && (state.paymentType === -1),
      message: 'Please select a payment service',
      value: state.paymentType,
    },
    videoConferenceLimit: {
      type: 'input',
      required: state.isVideoEnabled && isCcr,
      value: state.videoConferenceLimit,
      message: 'Video Conference Limit is required',
    },
    merchantId: {
      type: 'input',
      required: state.isRhinopayEnabled && isCcr,
      value: state.merchantId,
      message: 'Merchant ID is required',
      maxLength: 15,
    },
    merchantToken: {
      type: 'input',
      required: state.isRhinopayEnabled && isCcr && (state.paymentType !== breezeKey),
      value: state.merchantToken,
      message: 'Merchant Token is required',
    },
    paymentApiUsername: {
      type: 'input',
      required: state.isRhinopayEnabled && isCcr,
      value: state.paymentApiUsername,
      message: 'API Username is required',
      maxLength: 30,
    },
    paymentApiPassword: {
      type: 'input',
      required: state.isRhinopayEnabled && isCcr,
      value: state.paymentApiPassword,
      message: 'API Password is required',
      maxLength: 40,
    },
    paymentGatewayId: {
      type: 'input',
      required: state.isRhinopayEnabled && isCcr && (state.paymentType !== breezeKey),
      value: state.paymentGatewayId,
      message: 'Gateway ID is required',
    },
    rhinoformSeatsLimit: {
      type: 'input',
      required: state.isRhinoformEnabled && isCcr,
      value: state.rhinoformSeatsLimit,
      message: 'Max seats are required',
    },
    rhinoformUsername: {
      type: 'input',
      value: state.rhinoformUsername,
    },
    rhinoformPassword: {
      type: 'input',
      value: state.rhinoformPassword,
    },
    rhinoformEmail: {
      type: 'input',
      value: state.rhinoformEmail,
    },
  };
}

function shapeWidget(state) {
  return {
    formTitle: {
      type: 'input',
      value: state.widgetConfig.text.formTitle,
      required: true,
      message: 'Title is required.',
    },
    titleSubtext: {
      type: 'input',
      value: state.widgetConfig.text.titleSubtext,
      required: true,
      message: 'Title subtext is required.',
    },
    phonePlaceholder: {
      type: 'input',
      value: state.widgetConfig.text.phonePlaceholder,
      required: true,
      message: 'Phone placeholder is required.',
    },
    phoneHelpText: {
      type: 'input',
      value: state.widgetConfig.text.phoneHelpText,
      required: true,
      message: 'Helper text is required.',
    },
    messagePlaceholder: {
      type: 'input',
      value: state.widgetConfig.text.messagePlaceholder,
      required: true,
      message: 'Message placeholder is required.',
    },
    submitButton: {
      type: 'input',
      value: state.widgetConfig.text.submitButton,
      required: true,
      message: 'Button title is required.',
    },
    calltoActionButton: {
      type: 'input',
      value: state.widgetConfig.text.calltoActionButton,
      required: true,
      message: 'Action button title is required.',
    },
    confirmationText: {
      type: 'input',
      value: state.widgetConfig.text.confirmationText,
      required: true,
      message: 'Confirmation text is required.',
    },
  };
}

export function shapeBandwidthChannel(state) {
  const bandwidthChannel = {
    channelName: {
      type: 'channelName',
      required: true,
      value: state.channelName,
      maxLength: 60,
    },
    selectedNumber: {
      type: 'phone',
      required: true,
      value: state.existingNumber ? state.landlineNumber : state.bandwidthNumber,
    },
    forwardingNumber: {
      type: 'phone',
      required: !state.existingNumber, // Do not require forwardingNumber if existingNumber (landline) is true.
      value: state.bandwidthForwardingNumber,
    },
    timeZoneId: {
      type: 'select',
      required: true,
      value: state.timeZoneId,
    },
    route: {
      type: 'channelRoute',
      value: [state.selectedRouteMemberId, state.selectedRouteGroupId],
      required: true,
    },
  };
  if (state.isWebWidgetEnabled) {
    return { ...bandwidthChannel, ...shapeWidget(state) };
  }
  return bandwidthChannel;
}

export function shapeFacebookChannel(state) {
  return {
    channelName: {
      type: 'channelName',
      required: true,
      value: state.channelName,
      maxLength: 60,
    },
    timeZoneId: {
      type: 'select',
      required: true,
      value: state.timeZoneId,
    },
    route: {
      type: 'channelRoute',
      value: [state.selectedRouteMemberId, state.selectedRouteGroupId],
      required: true,
    },
    facebookPageId: {
      type: 'input',
      required: true,
      value: state.facebookPageId,
    },
  };
}

export function shapeInstagramChannel(state) {
  return {
    channelName: {
      type: 'channelName',
      required: true,
      value: state.channelName,
      maxLength: 60,
    },
    timeZoneId: {
      type: 'select',
      required: true,
      value: state.timeZoneId,
    },
    route: {
      type: 'channelRoute',
      value: [state.selectedRouteMemberId, state.selectedRouteGroupId],
      required: true,
    },
    pageId: {
      type: 'input',
      required: true,
      value: state.pageId,
    },
    instagramAccountId: {
      type: 'input',
      required: true,
      value: state.instagramAccountId,
    },
  };
}

export function shapeRhinogramChannel(state) {
  return {
    channelName: {
      type: 'channelName',
      required: true,
      value: state.channelName,
      maxLength: 60,
    },
    timeZoneId: {
      type: 'select',
      required: true,
      value: state.timeZoneId,
    },
    route: {
      type: 'channelRoute',
      value: [state.selectedRouteMemberId, state.selectedRouteGroupId],
      required: true,
    },
  };
}

export function shapeTwilioChannel(state) {
  const twilioChannel = {
    channelName: {
      type: 'channelName',
      required: true,
      value: state.channelName,
      maxLength: 60,
    },
    twilioNumber: {
      type: 'phone',
      required: true,
      value: state.twilioNumber,
    },
    timeZoneId: {
      type: 'select',
      required: true,
      value: state.timeZoneId,
    },
    route: {
      type: 'object',
      value: state.route,
      required: true,
    },
  };
  if (state.isWebWidgetEnabled) {
    return { ...twilioChannel, ...shapeWidget(state) };
  }
  return twilioChannel;
}

export function shapeLandlineChannel(state) {
  const landlineChannel = {
    channelName: {
      type: 'channelName',
      required: true,
      value: state.channelName,
      maxLength: 60,
    },
    landlineNumber: {
      type: 'phone',
      required: true,
      value: state.landlineNumber,
    },
    timeZoneId: {
      type: 'select',
      required: true,
      value: state.timeZoneId,
    },
    route: {
      type: 'channelRoute',
      value: [state.selectedRouteMemberId, state.selectedRouteGroupId],
      required: true,
    },
  };
  if (state.isWebWidgetEnabled) {
    return { ...landlineChannel, ...shapeWidget(state) };
  }
  return landlineChannel;
}

export function shapeSecureChannel(state) {
  return {
    channelName: {
      type: 'channelName',
      required: true,
      value: state.channelName,
      maxLength: 60,
    },
    timeZoneId: {
      type: 'select',
      required: true,
      value: state.timeZoneId,
    },
    route: {
      type: 'channelRoute',
      value: [state.selectedRouteMemberId, state.selectedRouteGroupId],
      required: true,
    },
  };
}

export function shapeMessageTemplate(state) {
  return {
    subject: {
      type: 'input',
      required: true,
      value: state.subject,
    },
    message: {
      type: 'input',
      required: true,
      value: state.message,
    },
  };
}

export function shapeSystemAlert(state) {
  return {
    message: {
      type: 'input',
      required: true,
      value: state.alertMessage,
      maxLength: 129,
    },
  };
}

export function shapeTag(state) {
  return {
    tagName: {
      type: 'tag',
      required: true,
      value: state.tagName,
      maxLength: 20,
      message: 'Please enter a valid tag name',
    },
  };
}

export function shapeRole(state) {
  return {
    name: {
      type: 'roleName',
      required: true,
      value: state.name,
      maxLength: 35,
      message: 'Role name is required',
    },
    description: {
      type: 'roleDescription',
      value: state.description,
      maxLength: 100,
    },
    permissions: {
      type: 'rolePermissions',
      required: true,
      value: state.selectedPermissions,
      message: 'Roles require at least one permission.',
    },
  };
}

export function shapeGroup(state) {
  return {
    name: {
      type: 'input',
      required: true,
      value: state.name,
    },
    purpose: {
      type: 'input',
      value: state.purpose,
    },
    users: {
      type: 'array',
      value: state.userIds,
      required: true,
    },
    timeZoneId: {
      type: 'select',
      required: state.afterHours,
      value: state.timeZoneId,
    },
  };
}

export function shapeOrgPreferences(state, supportsAppointmentReminders) {
  return {
    sessionTimeoutMinutes: {
      type: 'sessionTimeoutMinutes',
      required: true,
      value: state.sessionTimeoutMinutes,
    },
    selectedChannel: {
      type: 'select',
      required: supportsAppointmentReminders,
      value: state.defaultApptReminderChannelId,
      message: 'Please select a channel.',
    },
  };
}

export function shapeAppointmentCampaignDefaultChannel(state) {
  return {
    selectedChannel: {
      type: 'select',
      required: true,
      value: state.defaultApptReminderChannelId,
      message: 'Please select a channel.',
    },
  };
}

export function shapePaymentRequest(state) {
  return {
    paymentRequestAmount: {
      type: 'paymentAmount',
      required: true,
      value: state.paymentRequestAmount,
      message: 'Please enter a payment amount.',
    },
    paymentRequestNote: {
      type: 'input',
      required: false,
      value: state.paymentRequestNote,
    },
  };
}

export function shapeRhinopayCreditCardPaymentForBreeze(state) {
  return {
    nameOnCard: {
      type: 'input',
      required: true,
      value: state.nameOnCard,
      message: 'Please enter a name.',
    },
    cardNumber: {
      type: 'input',
      required: true,
      value: state.cardNumber,
      message: 'Please enter a credit card.',
    },
    expirationDate: {
      type: 'input',
      required: true,
      value: state.expirationDate,
      message: 'Expiration is required.',
    },
  };
}

export function shapeRhinopayCheckPaymentForBreeze(state) {
  return {
    accountType: {
      type: 'select',
      required: true,
      value: state.accountType,
      message: 'Account type is required.',
    },
    nameOnCheck: {
      type: 'input',
      required: true,
      value: state.nameOnCheck,
      message: 'Please enter a name.',
    },
    bankRoutingNumber: {
      type: 'input',
      required: true,
      value: state.bankRoutingNumber,
      message: 'Routing number is required.',
    },
    bankAccountNumber: {
      type: 'input',
      required: true,
      value: state.bankAccountNumber,
      message: 'Account number is required.',
    },
  };
}

export function shapeRhinopayCreditCardPayment(state) {
  return {
    nameOnCard: {
      type: 'input',
      required: true,
      value: state.nameOnCard,
      message: 'Please enter a name.',
    },
    cardNumber: {
      type: 'input',
      required: true,
      value: state.cardNumber,
      message: 'Please enter a credit card.',
    },
    expirationDate: {
      type: 'input',
      required: true,
      value: state.expirationDate,
      message: 'Expiration is required.',
    },
    cvv: {
      type: 'input',
      required: true,
      value: state.cvv,
      message: 'CVV is required.',
    },
    street: {
      type: 'input',
      required: true,
      value: state.street,
      message: 'Street address is required.',
    },
    zipcode: {
      type: 'zip',
      required: true,
      value: state.zipcode,
      message: 'Zip code is required.',
    },
  };
}

export function shapeRhinopayCheckPayment(state) {
  return {
    nameOnCheck: {
      type: 'input',
      required: true,
      value: state.nameOnCheck,
      message: 'Please enter a name.',
    },
    checkNumber: {
      type: 'input',
      required: true,
      value: state.checkNumber,
      message: 'Check number is required.',
    },
    bankRoutingNumber: {
      type: 'input',
      required: true,
      value: state.bankRoutingNumber,
      message: 'Routing number is required.',
    },
    bankAccountNumber: {
      type: 'input',
      required: true,
      value: state.bankAccountNumber,
      message: 'Account number is required.',
    },
  };
}

export function shapeAutomatedMessage(state) {
  return {
    name: {
      type: 'input',
      required: true,
      value: state.name,
      message: 'Name is required.',
    },
    appointmentReminder1Template: {
      type: 'input',
      required: state.appointmentReminder1Enabled,
      value: state.appointmentReminder1Template,
      message: 'Please enter a message.',
    },
    appointmentReminder1DeliveryHours: {
      type: 'number',
      required: state.appointmentReminder1Enabled,
      value: state.appointmentReminder1DeliveryHours,
      message: 'Please enter a number.',
    },
    appointmentReminder2Template: {
      type: 'input',
      required: state.appointmentReminder2Enabled,
      value: state.appointmentReminder2Template,
      message: 'Please enter a message.',
    },
    appointmentReminder2DeliveryHours: {
      type: 'number',
      required: state.appointmentReminder2Enabled,
      value: state.appointmentReminder2DeliveryHours,
      message: 'Please enter a number.',
    },
    appointmentReminder3Template: {
      type: 'input',
      required: state.appointmentReminder3Enabled,
      value: state.appointmentReminder3Template,
      message: 'Please enter a message.',
    },
    appointmentReminder3DeliveryHours: {
      type: 'number',
      required: state.appointmentReminder3Enabled,
      value: state.appointmentReminder3DeliveryHours,
      message: 'Please enter a number.',
    },
    appointmentReminder4Template: {
      type: 'input',
      required: state.appointmentReminder4Enabled,
      value: state.appointmentReminder4Template,
      message: 'Please enter a message.',
    },
    appointmentReminder4DeliveryHours: {
      type: 'number',
      required: state.appointmentReminder4Enabled,
      value: state.appointmentReminder4DeliveryHours,
      message: 'Please enter a number.',
    },
    appointmentScheduledTemplate: {
      type: 'input',
      required: state.appointmentScheduledEnabled,
      value: state.appointmentScheduledTemplate,
      message: 'Please enter a message.',
    },
    offices: {
      type: 'array',
      required: true,
      value: state.offices,
      message: 'You must select at least one office.',
    },
  };
}

export function shapePrescriptionMessage(state) {
  return {
    name: {
      type: 'input',
      required: true,
      value: state.name,
      message: 'Name is required.',
    },
    payors: {
      type: 'array',
      required: !state.default,
      value: state.payors,
      message: 'You must select at least one payor.',
    },
  };
}

export function shapeHipaaCampaignMessage(state) {
  return {
    name: {
      type: 'input',
      required: true,
      value: state.name,
      message: 'Name is required.',
    },
    template: {
      type: 'input',
      required: state.snabled,
      value: state.template,
      message: 'Please enter a message.',
    },
    hipaaStatusTypeId: {
      type: 'select',
      required: true,
      value: state.hipaaStatusTypeId,
      message: 'Please select a status.',
    },
  };
}

export function shapeWebWidget(state, token) {
  return {
    firstName: {
      type: 'input',
      value: state.firstName,
      required: true,
    },
    lastName: {
      type: 'input',
      value: state.lastName,
      required: true,
    },
    question: {
      type: 'input',
      value: state.question,
      required: true,
    },
    phone: {
      type: 'phone',
      value: state.phone,
      required: true,
    },
    reCaptcha: {
      type: 'captcha',
      value: token,
      required: true,
    },
  };
}

export function shapeThreadContentSelection(state) {
  return {
    contentSelectionTitle: {
      type: 'input',
      required: true,
      value: state.contentSelectionTitle,
      message: 'Title is required',
    },
    contentSelectionNote: {
      type: 'input',
      required: false,
      value: state.contentSelectionNote,
    },
    selectedEventIds: {
      type: 'array',
      required: true,
      value: state.selectedEventIds,
      message: 'You must select at least one event to save',
    },
  };
}

export function shapeRhinoform(formName) {
  return {
    formName: {
      type: 'input',
      required: true,
      value: formName,
      message: 'Please enter form name',
    },
  };
}

export function shapeAppointmentTypes(state) {
  return {
    alias: {
      type: 'input',
      required: true,
      value: state.alias,
      message: 'Alias is required',
    },
  };
}

export function shapeCreateTag(state) {
  return {
    name: {
      type: 'tag',
      required: true,
      value: state.name,
      maxLength: 20,
      message: 'Please enter a valid tag name',
    },
  };
}

export function shapeCategoryRouting(state) {
  return {
    textClassificationTypes: {
      type: 'array',
      required: true,
      value: state.textClassificationTypes,
      message: 'You must select at least one category.',
    },
    toGroupId: {
      type: 'categoryRoute',
      value: [state.toGroupId, state.toMemberId],
      message: 'You must select a routing path.',
      required: true,
    },
  };
}
