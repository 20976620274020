import moment from 'moment-timezone';
import { capitalize } from '../../helpers/StringHelpers';
import responses from './responses';
import { AppConstants } from '../../constants';

const ValidationService = (opts) => {
  // If the service is not provided opts, or it is an invalid / empty object, immediately return.
  if (!opts || typeof opts !== 'object' || !Object.keys(opts).length) {
    return {};
  }

  return initialize(opts);
};

function initialize(opts) {
  const returnVal = {};

  Object.keys(opts).forEach((key) => {
    returnVal[key] = runValidation(opts[key], key);

    // If there is no value after running through validations, remove the property from the return.
    if (!returnVal[key]) {
      delete returnVal[key];
    } else if (typeof returnVal[key] === 'object' && !Object.keys(returnVal[key]).length) {
      delete returnVal[key];
    }
  });

  return returnVal;
}

function runValidation(item, key) {
  if (!item) {
    return null;
  }

  if (!item.value && !item.required) {
    return null;
  }

  let returnVal;
  switch (item.type) {
    case 'input':
      returnVal = validateInput(item, key);
      break;
    case 'select':
      returnVal = validateSelect(item, key);
      break;
    case 'birthday':
      returnVal = validateBirthday(item, key);
      break;
    case 'email':
      returnVal = validateEmail(item, key);
      break;
    case 'emails':
      returnVal = validateEmails(item, key);
      break;
    case 'phone':
      returnVal = validatePhone(item, key);
      break;
    case 'phones':
      returnVal = validatePhones(item, key);
      break;
    case 'city':
      returnVal = validateCity(item, key);
      break;
    case 'state':
      returnVal = validateState(item, key);
      break;
    case 'zip':
      returnVal = validateZip(item, key);
      break;
    case 'channelName':
      returnVal = validateChannelName(item, key);
      break;
    case 'number':
      returnVal = validateNumber(item, key);
      break;
    case 'tag':
      returnVal = validateTag(item, key);
      break;
    case 'roleName':
      returnVal = validateRoleName(item, key);
      break;
    case 'roleDescription':
      returnVal = validateRoleDescription(item, key);
      break;
    case 'rolePermissions':
      returnVal = validateRolePermissions(item, key);
      break;
    case 'memberRoles':
      returnVal = validateMemberRoles(item, key);
      break;
    case 'array':
      returnVal = validateArray(item, key);
      break;
    case 'channelRoute':
      returnVal = validateChannelRoute(item, key);
      break;
    case 'categoryRoute':
      returnVal = validateCategoryRoute(item, key);
      break;
    case 'creditcard':
      returnVal = validateCreditCard(item, key);
      break;
    case 'cvv':
      returnVal = validateCvv(item, key);
      break;
    case 'contactInput':
      returnVal = validateContactInput(item, key);
      break;
    case 'sessionTimeoutMinutes':
      returnVal = validateSessionTimeoutMinutes(item, key);
      break;
    case 'captcha':
      returnVal = validateCaptcha(item, key);
      break;
    case 'paymentAmount':
      returnVal = validatePaymentAmount(item, key);
      break;
    case 'organizationNameInput':
      returnVal = validateOrganizationNameInput(item, key);
      break;
    default:
      returnVal = null;
  }

  return returnVal;
}

function validateNumber(item, key) {
  return item && item.value.match(/^[1-9]\d*(\.\d+)?$/) ? null : item.message || responses[key];
}

function validateSelect(item, key) {
  return (!item.value || item.value === undefined || item.value === -1) && item.required ? item.message || responses[key] : null;
}

function validateCreditCard(item, key) {
  if (!item.value || item.value === undefined) {
    return responses[key];
  }
  return (item && item.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/) ? null : item.message || 'Invalid credit card number'); // eslint-disable-line max-len
}

function validateCvv(item, key) {
  if (!item.value || item.value === undefined) {
    return responses[key];
  }
  return (item && item.value.match(/^[0-9]{3,4}$/) ? null : item.message || 'Invalid cvv number');
}

function validateContactInput(item, key) {
  if (item.value && item.maxLength && item.value.length > item.maxLength) {
    return 'over maximum allowed length';
  }
  if (item.value && !item.value.match(/^[\w\s.'",]{1,30}$/)) {
    return 'Expected 30 character max with only spaces, numbers and letters.';
  }
  return !item.value && item.required ? item.message || responses[key] : null;
}

function validateSessionTimeoutMinutes(item, key) {
  return (!item.value || (item.value < AppConstants.SESSION_TIMEOUT_MINUTES_MIN || item.value > AppConstants.SESSION_TIMEOUT_MINUTES_MAX)) ?
    item.message || responses[key] : null;
}

function validateArray(item, key) {
  return !item.value.length && item.required ? item.message || responses[key] : null;
}

function validateChannelRoute(item, key) {
  return item.value.some((el) => el !== -1) ? null : item.message || responses[key];
}

function validateCategoryRoute(item, key) {
  return item.value.some((el) => el !== null) ? null : item.message || responses[key];
}

function validateInput(item, key) {
  if (item.isDisabled) {
    return null;
  }
  if (item.value && item.maxLength && item.value.length > item.maxLength) {
    return `${capitalize(key)} over maximum allowed length`;
  }

  return !item.value && item.required ? item.message || responses[key] : null;
}

function validatePaymentAmount(item, key) {
  return (!item.value || parseInt(item.value * 100, 10) < 1) && item.required ? item.message || responses[key] : null;
}

function validateCaptcha(item, key) {
  return item.required && (!item.value || item.value.length === 0) ? item.message || responses[key] : null;
}

function validateBirthday(item, key) {
  if (item.isDisabled) {
    return null;
  }

  if ((!item.value && item.required)) {
    return responses[key].invalid;
  }

  if (!moment(item.value, 'YYYY-MM-DD', true).isValid()) {
    return responses[key].invalid;
  }

  if (moment().format('YYYY-MM-DD') < item.value) {
    return responses[key].future;
  }

  return null;
}

function validateEmail(item, key) {
  if (!item.value && item.required) {
    return responses.isRequired('Email Address');
  } else if (!item.value && !item.required) {
    return null;
  }

  return item.value.match(/^[A-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]+$/i) ? null : item.message || responses[key]; // eslint-disable-line max-len
}

function validateEmails(item, key) {
  if ((!item.value && !item.required) || item.isDisabled) {
    return null;
  }

  const returnVal = {};

  Object.keys(item.value).forEach((e, i) => {
    returnVal[`${key}-${i}`] = item.value[e].value ? validateEmail(item.value[e], key) : null;

    if (!returnVal[`${key}-${i}`]) {
      delete returnVal[`${key}-${i}`];
    }
  });

  return returnVal;
}

function validatePhone(item, key) {
  const response = item.message || responses[key] || responses.phone;

  if (!item.value && item.required) {
    return response;
  } else if (!item.value && !item.required) {
    return null;
  }

  return stripPhone(item.value).match(/^\+1[0-9]{10}$/) ? null : response;

  function stripPhone(value) {
    let returnVal = value.replace(/[^a-zA-Z0-9]/g, '');

    if (returnVal[0] === '1') {
      returnVal = `+${returnVal}`;
    } else {
      returnVal = `+1${returnVal}`;
    }

    return returnVal;
  }
}

function validatePhones(item, key) {
  if ((!item.value && !item.required) || item.isDisabled) {
    return null;
  }

  const returnVal = {};

  Object.keys(item.value).forEach((p, i) => {
    returnVal[`${key}-${i}`] = item.value[p].value ? validatePhone(item.value[p], key) : null;

    if (!returnVal[`${key}-${i}`]) {
      delete returnVal[`${key}-${i}`];
    }
  });

  return returnVal;
}

function validateRoleName(item, key) {
  if (item.value && item.maxLength && item.value.length > item.maxLength) {
    return `Role Name over maximum allowed length (${item.maxLength})`;
  }
  return item && item.required && item.value ? null : item.message || responses[key];
}

function validateRoleDescription(item, key) {
  if (item.value && item.maxLength && item.value.length > item.maxLength) {
    return `Role Description over maximum allowed length (${item.maxLength})`;
  }
  return item && item.value ? null : item.message || responses[key];
}

function validateRolePermissions(item, key) {
  if (item.value && item.value.length < 1) {
    return 'Roles require at least one permission.';
  }
  return item && item.value.length > 0 ? null : item.message || responses[key];
}

function validateMemberRoles(item, key) {
  if (item.value && item.value.length < 1) {
    return 'Members require at least one role.';
  }
  return item && item.value.length > 0 ? null : item.message || responses[key];
}

function validateTag(item, key) {
  if (item && item.required && !item.value) {
    return responses.isRequired('Tag Name');
  }

  if (item.value && item.maxLength && item.value.length > item.maxLength) {
    return 'Tag Name over maximum allowed length';
  }

  if (['location', 'department', 'role', 'custom'].includes(item.value.toLowerCase())) {
    return 'Tag Name cannot be a category';
  }

  return item && item.required && !item.value.match(/[^\w-]/g) ? null : item.message || responses[key];
}

function validateCity(item, key) {
  if (item && item.required && !item.value) {
    return responses.isRequired('City');
  }

  return item && item.required && item.value.match(/^[a-zA-Z0-9À-ž- ]{1,30}$/) ? null : item.message || responses[key];
}

function validateState(item, key) {
  if (item && item.required && !item.value) {
    return responses.isRequired('State');
  }

  return item && item.required && (item.value !== -1 && item.value.match(/^[a-zA-Z]{2}$/)) ? null : item.message || responses[key];
}

function validateZip(item, key) {
  if (item && item.required && !item.value) {
    return responses.isRequired('Zip');
  }

  return item && item.required && item.value.match(/^\d{5}(?:[-+\s]\d{4})?$/) ? null : item.message || responses[key];
}

function validateChannelName(item, key) {
  if (item && item.required && !item.value) {
    return responses.isRequired('Name');
  }

  return item && item.required && item.value.match(/^[\w\s.&_'"-]{1,60}$/) ? null : item.message || responses[key];
}

function validateOrganizationNameInput(item, key) {
  if (item.value && !item.value.match(/^[\w\s.'"!@#,&:;()/-]{1,80}$/)) {
    return `'${item.value}' is invalid.  Expected 80 character max with only spaces,
    numbers and letters and the following special characters: ! @ # & (  ) - ' " ; : / , _.`;
  }
  return !item.value && item.required ? item.message || responses[key] : null;
}

export default ValidationService;
